import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Container, Card, CardContent } from "@mui/material";

function Dashboard() {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState("");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://api-source.sandbox.cauribourse.com/api/posts");
        const data = await response.json();
        setPosts(data);
      } catch (err) {
        console.error("Failed to fetch posts", err);
      }
    };
    fetchPosts();
  }, []);

  const handlePost = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api-source.sandbox.cauribourse.com/api/posts", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ content: newPost }),
      });

      if (response.ok) {
        const newPostData = await response.json();
        setPosts([...posts, newPostData]);
        setNewPost("");
      }
    } catch (err) {
      console.error("Failed to post", err);
    }
  };

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <form onSubmit={handlePost}>
          <TextField
            fullWidth
            label="Write a post"
            margin="normal"
            value={newPost}
            onChange={(e) => setNewPost(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "1rem" }}
          >
            Post
          </Button>
        </form>
        <Box mt={4}>
          <Typography variant="h6">Your Posts</Typography>
          {posts.map((post, index) => (
            <Card key={index} style={{ marginBottom: "1rem" }}>
              <CardContent>
                {/* Insérer du contenu directement sans échapper */}
                <div dangerouslySetInnerHTML={{ __html: post.content }} />
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

export default Dashboard;
