import React, { useState, useEffect } from "react";
import { Box, Typography, Container, Card, CardContent, CircularProgress, Button } from "@mui/material";

function Profile() {
  const [userInfo, setUserInfo] = useState(null);
  const [image, setImage] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(""); // URL de l'image téléchargée
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Récupérer les informations du profil
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await fetch("https://api-source.sandbox.cauribourse.com/api/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.ok) {
          const data = await response.json();
          setUserInfo(data);
        }
      } catch (err) {
        console.error("Failed to fetch profile", err);
      } finally {
        setLoading(false);
      }
    };
    fetchProfile();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleUpload = async () => {
    if (!image) return;
    setLoading(true);

    const formData = new FormData();
    formData.append("image", image);

    try {
      const response = await fetch("https://api-source.sandbox.cauribourse.com/api/upload", {
        method: "POST",
        headers: { role: "user" },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setMessage("Image uploaded successfully!");
        setUploadedImageUrl(data.imageUrl); // Récupérer l'URL de l'image téléchargée
      } else {
        setMessage("Upload failed.");
      }
    } catch (error) {
      setMessage("Error uploading image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        {loading ? (
          <CircularProgress />
        ) : userInfo ? (
          <Card style={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                User Profile
              </Typography>
              <Typography variant="body1">
                <strong>Username:</strong> {userInfo.username}
              </Typography>
              <Typography variant="body1">
                <strong>Role:</strong> {userInfo.role}
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Typography variant="h6" color="error">
            No user information available.
          </Typography>
        )}

        <Box mt={4} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Upload Image
          </Typography>
          <input type="file" onChange={handleImageChange} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={loading}
            style={{ marginTop: "1rem" }}
          >
            Upload
          </Button>
          {loading && <CircularProgress style={{ marginTop: "1rem" }} />}
          {message && <Typography style={{ marginTop: "1rem" }}>{message}</Typography>}
        </Box>

        {/* Afficher l'image téléchargée dès qu'elle est uploadée */}
        {uploadedImageUrl && (
          <Box mt={4} textAlign="center">
            <Typography variant="h5">Uploaded Image</Typography>
            <img
              src={uploadedImageUrl}
              alt="Uploaded"
              style={{ marginTop: "1rem", maxWidth: "100%", maxHeight: "400px" }}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Profile;
