import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Switch,
  FormControlLabel,
} from "@mui/material";

function Settings() {
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState(true);

  const handleSave = () => {
    alert("Settings saved successfully!");
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" mt={4}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Box mb={2}>
          <Typography variant="h6">Account Settings</Typography>
          <TextField
            fullWidth
            label="Email Address"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Preferences</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={notifications}
                onChange={(e) => setNotifications(e.target.checked)}
              />
            }
            label="Enable Notifications"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: "1rem" }}
        >
          Save Changes
        </Button>
      </Box>
    </Container>
  );
}

export default Settings;
